import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { OptionComponent } from './core/option/option.component';
import { SmoothScrollModule } from 'src/app/directives/smooth-scroll/smooth-scroll.module';
import { ObserversModule } from '@angular/cdk/observers';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SmoothScrollbarModule } from '../smooth-scrollbar/smooth-scrollbar.module';
import { SelectLargeListComponent } from './select-large-list/select-large-list.component';



@NgModule({
  declarations: [
    SelectComponent,
    OptionComponent,
    SelectLargeListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SmoothScrollModule,
    OverlayModule,
    ObserversModule,
    ReactiveFormsModule,
    SmoothScrollbarModule
  ],
  exports: [
    SelectComponent,
    SelectLargeListComponent,
    OptionComponent
  ],
  providers: [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}],
})
export class SelectModule { }
