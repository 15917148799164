import { Injectable, OnDestroy } from "@angular/core";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class MemberPagination extends MatPaginatorIntl {
    constructor(
      private translate: TranslateService
    ) {
        super();
        this.initialize();
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
          return '0 ' + this.translate.instant('general.of') + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + " " + this.translate.instant('general.of') + " " + length;
    };
    initialize(): void {
      this.firstPageLabel = '';
      this.lastPageLabel = '';
      this.nextPageLabel = '';
      this.itemsPerPageLabel = '';
      this.previousPageLabel = '';
      this.changes.next();
    }
}
