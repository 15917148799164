<div class="dialog-header">
  <p mat-dialog-title>{{data?.title}}</p>
</div>

<mat-dialog-content class="mt-3">
  <app-file-drag-drop accept="image/jpeg,image/png" (fileChange)="onFileChange($event)">
    <img width="120" [src]="photoSource || '/assets/icons/user.svg'">
  </app-file-drag-drop>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" class="m-button-outlined s-md set-w">
    <mat-icon style="transform: rotateY(180deg)">logout</mat-icon>{{'button.close' | translate}}
  </button>
  <button mat-raised-button class="m-button-primary s-md approve" [disabled]="!photo.value"
    [mat-dialog-close]="photo.value">
    <mat-icon>check</mat-icon>{{data?.title}}
  </button>
</mat-dialog-actions>
