<div class="dropdown">
    <button [disabled]="disabled" type="button" class="dropdown-toggle form-control" data-toggle="dropdown" aria-expanded="false">
        {{ valueText }}
    </button>
    <div class="dropdown-menu">
        <ng-container *ngIf="enableSearch">
            <input [formControl]="search" class="form-control txt-search" [placeholder]="'general.search' | translate">
            <div class="dropdown-divider"></div>
        </ng-container>
        <ng-content></ng-content>
    </div>
</div>