import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit {
  @Input() text: string = "";
  @Input() enableDownload: boolean = true;
  @Input() enableView: boolean = true;
  @Input() enableDelete: boolean = true;
  @Input() svgIcon = "";
  // I ADD ON BEFORE CAUSE delete is an keyword
  @Output() onDownload = new EventEmitter<void>();
  @Output() onView = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();

  @Input() rowClass = "";
  // @Input() comparing: boolean = false;
  // @Input() deleted: boolean | null = null;
  // @Input() old: boolean | null = null;

  ngOnInit(): void {
  }

  emitAction(output: EventEmitter<any>): void {
    output.emit();
  }

}
