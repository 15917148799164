import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
	selector: 'app-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
	animations: [
		trigger('popin', [
			transition(":enter", [
				style({ height: 0, opacity: 0 }),
				animate("400ms ease-in", style({ height: '*', opacity: 1 }))
			]),
			transition(":leave", [
				animate("400ms ease-out", style({ height: 0, opacity: 0 }))
			])
		])
	]
})
export class PdfViewerComponent implements OnInit {
	url: string = '';
	zoom = 'page-width';
	initIframe: boolean = false;


	constructor(
		@Inject(MAT_DIALOG_DATA) public data?: any
	) {
	}

	ngOnInit(): void {
		this.url = this.data.image;

		setTimeout(() => {
			this.initIframe = true;
		}, 350);
	}
}
