import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { lastValueFrom, timer } from 'rxjs';
export interface FileViewerDialogData {
  url: string
}

// transition(":enter", [
//   style({ transform: 'scaleY(0)' }),
//   animate("350ms ease-out", style({ transform: 'scaleY(1)' }))
// ])

@Component({
  selector: 'app-file-viewer-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './file-viewer-dialog.component.html',
  styleUrls: ['./file-viewer-dialog.component.scss']
})
export class FileViewerDialogComponent implements OnInit {
  url: SafeResourceUrl;
  zoom = 'page-width';
  isVisible: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileViewerDialogData,
    private cd: ChangeDetectorRef,
    private domSanitizer: DomSanitizer
  ) {
    this.cd.detach();
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  ngOnInit(): void {
    // this.cd.detectChanges();
    this.visible();
  }

  async visible(): Promise<void> {
    await lastValueFrom(timer(100));
    this.isVisible = true;
    this.cd.detectChanges();
    // this.cd.markForCheck();
  }
}
