import { AfterViewInit, Directive, ElementRef, OnDestroy, Output, EventEmitter, NgZone } from '@angular/core';
import SmoothScrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import { ScrollListener, ScrollStatus } from 'smooth-scrollbar/interfaces';
import { Scrollbar } from 'smooth-scrollbar/scrollbar';

// SmoothScrollbar.use(OverscrollPlugin);
@Directive({
  selector: '[appSmoothScroll]',
  exportAs: 'smoothScroll'
})
export class SmoothScrollDirective implements AfterViewInit, OnDestroy {
  smoothScrollRef!: Scrollbar;
  @Output() smoothscroll: EventEmitter<ScrollStatus> = new EventEmitter<ScrollStatus>();
  constructor(
    private el: ElementRef,
    private _ngZone: NgZone
  ) {
  }

  onScroll = (scrollStatus: ScrollStatus) => {
      this.updateContinuosScrolling();
      this.smoothscroll.emit(scrollStatus);
  }

  ngAfterViewInit(): void {
    this.smoothScrollRef = this._ngZone.runOutsideAngular(() => SmoothScrollbar.init(this.el.nativeElement));
    this.updateContinuosScrolling();
    // this.smoothScrollRef.
    this.smoothScrollRef.addListener(this.onScroll);
  }

  updateContinuosScrolling(): void {
    const isScroll = this.el.nativeElement.clientWidth < this.el.nativeElement.scrollWidth || this.el.nativeElement.clientHeight < this.el.nativeElement.scrollHeight;
    this.smoothScrollRef.options.continuousScrolling = !isScroll;
  }

  ngOnDestroy(): void {
    this.smoothScrollRef.removeListener(this.onScroll);
    this.smoothScrollRef.destroy();
  }

}
