<h2 mat-dialog-title>
  {{ data.title ? (data.title | translate) : ("button.allocate" | translate) }}
</h2>

<mat-dialog-content>
  <form [formGroup]="formDataGroup" #form="ngForm">
    <div class="form-row">
      <div class="m-form col-md-12 form-group mb-3 pl-0 pr-0">
        <label class="required">
          {{ "public.assign_dialog.receiver_lbl" | translate }}
          ({{ "public.assign_dialog.office" | translate }})
        </label>
        <app-select
          formControlName="to_department"
          (selectionChange)="getUserByDepartment($event)"
        >
          <app-option
            *ngFor="let department of departments"
            [value]="department._id"
          >
            {{
              lang != LANG.EN || !department.name_en
                ? department.name_kh
                : department.name_en
            }}
          </app-option>
        </app-select>
        <!-- <div class="dropdown" id="receiver">
						<input type="text" formControlName="to_department" style="display: none;">
						<button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<p>{{ !selectedDepartment ? '' : lang != 'en' ? selectedDepartment?.name_kh : selectedDepartment?.name_en }}</p>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<div class="list-item">
								<p *ngFor="let item of departments" (click)="selectedDepartment = item; getUserByDepartment(item?._id); formDataGroup.get('to_department')?.setValue(item?._id);" class="dropdown-item">
									{{ lang != 'en' ? item?.name_kh : item?.name_en }}
								</p>
								<p *ngIf="departments?.length == 0" style="text-align: center;">{{ 'general.empty_list' | translate }}</p>
							</div>
						</div>
					</div> -->
      </div>
      <div class="m-form col-md-12 form-group mb-3 pl-0 pr-0">
        <label [ngClass]="{ required: data?.required }">
          {{ "public.assign_dialog.receiver_lbl" | translate }}
          ({{ "public.assign_dialog.receiver" | translate }})
        </label>
        <app-select formControlName="to_user">
          <app-option *ngFor="let user of users" [value]="user._id">
            {{ getUserName(user) }}
          </app-option>
        </app-select>
        <!-- <div class="dropdown" id="receiver">
						<input type="text" formControlName="to_user" style="display: none;">
						<button *ngIf="!selectedDepartment" class="dropdown-toggle btn-disabled"></button>
						<button *ngIf="selectedDepartment" class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<p>{{ getUserName(selectedUser) }}</p>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<div class="list-item">
								<p *ngFor="let item of users" (click)="selectedUser = item; formDataGroup.get('to_user')?.setValue(item?._id);" class="dropdown-item">
									{{ getUserName(item) }}
								</p>
								<p *ngIf="users?.length == 0" style="text-align: center;">{{ 'general.empty_list' | translate }}</p>
							</div>
						</div>
					</div> -->
      </div>
      <div class="m-form col-md-12 form-group mb-3 pl-0 pr-0">
        <label>{{ "public.reason" | translate }}</label>
        <textarea
          formControlName="description"
          type="text"
          class="form-control"
          style="height: auto"
          rows="4"
          required
        ></textarea>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="m-button-outlined">
    <mat-icon style="transform: rotateY(180deg)">logout</mat-icon
    >{{ "button.cancel" | translate }}
  </button>
  <button
    mat-raised-button
    class="m-button-primary"
    [disabled]="
      formDataGroup.controls['to_department'].invalid ||
      (data.required && formDataGroup.controls['to_user'].invalid)
    "
    (click)="onSubmit()"
  >
    <mat-icon>save</mat-icon>{{ "button.submit" | translate }}
  </button>
</mat-dialog-actions>
