import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_OPTION_PARENT_COMPONENT } from '@angular/material/core';
import { SelectComponent } from '../select.component';

@Component({
  selector: 'app-select-large-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select-large-list.component.html',
  styleUrls: ['./select-large-list.component.scss'],
  host: {
    'class': 'select-large-list'
  },
  providers: [
    {
      provide: MAT_OPTION_PARENT_COMPONENT,
      useExisting: SelectLargeListComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectLargeListComponent
    }
  ]
})
export class SelectLargeListComponent extends SelectComponent {

  // constructor(
  //   cdr: ChangeDetectorRef,
  //   fb: FormBuilder
  // ) {
  //   super(cdr, fb);
  // }
  // ngOnInit(): void {
  //   this.search.valueChanges.subscribe(value => {
  //     this.textSearchChange.emit(value);
  //   })
  // }

}
