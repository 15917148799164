<div class="content">
    <mat-dialog-content>
        <img [src]='url' />
    </mat-dialog-content>


    <!-- <button class="option-btn" mat-icon-button [matMenuTriggerFor]="menu" matTooltip="menu">
        <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="updateImage()">
            <mat-icon style="font-size: 26px;">change_circle</mat-icon>
            {{'button.change_img' | translate}}
        </button>
    </mat-menu> -->
</div>