<div class="upload-dropper">
    <div class="content">
        <ng-content></ng-content>
    </div>
    <div class="upload-picker d-flex flex-column">
        <ng-container *ngIf="useInputFile; else labelClick">
            <input [attr.accept]="accept" (change)="onFileChange($event)" [id]="'search-photo-' + id" type="file" #search_photo hidden>
            <button mat-flat-button class="m-button-primary photo-picker" (click)="search_photo.value = ''; search_photo.click();">
                <label [for]="'search-photo-' + (id ? id : '')"></label>
                <mat-icon svgIcon="upload"></mat-icon>{{ 'general.browse_photo' | translate }}
            </button>
        </ng-container>
        <ng-template #labelClick>
            <button (click)="onLabelClick()" mat-flat-button color="primary-dark" class="photo-picker mat-button-reset" type="button">
                <mat-icon>photo_camera</mat-icon> Open Camera
            </button>
        </ng-template>
        <ng-container *ngIf="!(handset)">
            <div class="d-flex flex-row align-items-center line">
                <mat-divider class="left"></mat-divider>
                <span>{{ 'general.or' | translate }}</span>
                <mat-divider class="right"></mat-divider>
            </div>
            <div class="description">
                <span>{{ 'general.drag_drop_file' | translate }}</span>
            </div>
        </ng-container>
    </div>
</div>
