import { AfterViewInit, Directive, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { fromEvent } from 'rxjs';
import SmoothScrollbar from 'smooth-scrollbar';

@Directive({
  selector: '[appIntlSmoothScroll]'
})
export class IntlSmoothScrollDirective implements AfterViewInit, OnDestroy {
  smoothScroll!: SmoothScrollbar;

  constructor(
    private el: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    // const initialSmoothScroll = fromEvent(this.el.nativeElement.querySelector('.iti__selected-flag.dropdown-toggle'), "click")
    // .subscribe(() => {
    //   this.smoothScroll = SmoothScrollbar.init(this.querySelector('.iti__country-list'), { continuousScrolling: false });
    //   initialSmoothScroll.unsubscribe();
    // });
  }

  private querySelector(queryString: string): HTMLElement {
    return this.el.nativeElement.querySelector(queryString);
  }

  ngOnDestroy(): void {
    this.smoothScroll?.destroy();
  }

}
