import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  timer: any;
  currentTime!: number;
  expiredTime!: number;
  countdownTimer: any;

  lang: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private translate: TranslateService) {

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

    let token = localStorageService.get(LocalStorageEnum.token);
    if (token && data.is_expired_token) {
      this.expiredTime = parseInt(this.localStorageService.get(LocalStorageEnum.expiry_time));
      this.countdown();
    }
  }

  ngOnInit(): void {
  }

  countdown() {
    this.timer = setInterval(() => {

      // get current time in seconds
      this.currentTime = new Date().getTime() / 1000;

      // Find the distance between now and the expire date
      let distance = this.expiredTime - this.currentTime;

      // Time calculations for minutes and seconds
      let minutes = Math.floor((distance % (60 * 60)) / 60);
      let seconds = Math.floor(distance % 60);

      // Display format
      let F_min = minutes.toString().length == 1 ? '0' + minutes : minutes;
      let F_sec = seconds.toString().length == 1 ? '0' + seconds : seconds;

      this.countdownTimer = F_min + ':' + F_sec;


      if (minutes <= 0 && seconds <= 0) {
        this.offTimer();
        this.dialog.closeAll();
        this.authService.logout();
      }
    }, 1000);
  }

  offTimer() {
    clearInterval(this.timer);
  }
}
