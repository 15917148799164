import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImgUploadWithValidateComponent } from '../img-upload-with-validate/img-upload-with-validate.component';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  type = '';
  url: string | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: {
      url: string,
    }
  ) { }

  ngOnInit(): void {
    this.url = this.data?.url;
  }

  updateImage() {
    const _dialog = this.dialog.open(ImgUploadWithValidateComponent, {
      width: '500px',
      autoFocus: false,
      restoreFocus: false
    })
    _dialog.afterClosed().subscribe(file => {
      if (file) {
        this.dialogRef.close(file)
      }
    })
  }

  // isUpdateable() {
  //   let permissions = this.localstorageService.getArray(LocalStorage.permissions);
  //   let loan_statuses = ['draft', 'pending', 'approved'];

  //   if (permissions.includes("can_edit_loan") && loan_statuses.includes(this.loanStatus)) return true;
  //   else return false;
  // }

}
