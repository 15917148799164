<h2 mat-dialog-title>{{ data?.title || ('button.verify' | translate) }}</h2>
<mat-dialog-content class="custom-dialog-content">
    <p *ngIf="data?.is_expired_token" class="desc">
        {{ 'message_dialog.expires_in' | translate }}
        <span>{{ countdownTimer }}</span>
        {{ 'message_dialog.minute_left' | translate }}
    </p>
    <p class="desc">{{ 'message_dialog.token_expired' | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="m-button-outlined" mat-button [mat-dialog-close]="false" *ngIf="!data?.show_ok_btn_only">
        <mat-icon>close</mat-icon> {{ 'message_dialog.btn_no' | translate }}
    </button>
    <button class="m-button-primary" mat-raised-button [mat-dialog-close]="true" *ngIf="!data?.show_ok_btn_only">
        <mat-icon>check</mat-icon> {{ 'message_dialog.btn_yes' | translate }}
    </button>
    <button mat-raised-button class="m-button-primary" style="margin: 0px auto;" [mat-dialog-close]="true"
        *ngIf="data?.show_ok_btn_only">
        {{ 'message_dialog.btn_yes' | translate }}
    </button>
</mat-dialog-actions>
