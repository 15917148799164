<ng-container
  *ngTemplateOutlet="
    navigation;
    context: { $implicit: { menuList: menu, isSubMenu: false } }
  "
></ng-container>
<ng-template #navigation let-data>
  <mat-nav-list
    class="menu"
    [ngClass]="{ 'sub-menu': data.isSubMenu && !isMenuIcon, 'main-menu': !data.isSubMenu, 'sub-menu-icon-only': data.isSubMenu && isMenuIcon}"
    @subItem
  >
    <ng-container *ngFor="let menu of data.menuList">
      <li class="list-menu" *ngIf="!menu.isNoPermission">
        <ng-container *ngIf="isMenuIcon; else fullMenu">
          <a
            mat-list-item
            *ngIf="!menu.children; else menuGroupIcon"
            routerLinkActive="active"
            (click)="onClickMenu()"
            matTooltipPosition="right"
            [matTooltip]="menu.title | translate"
            [routerLink]="menu.routerLink"
          >
            <mat-icon>{{ menu.icon }} </mat-icon>
            <!-- <span class="menu-title">{{ menu.title | translate }}</span> -->
          </a>
        </ng-container>
        <ng-template #fullMenu>
          <a
            mat-list-item
            *ngIf="!menu.children; else menuGroup"
            routerLinkActive="active"
            (click)="onClickMenu()"
            [routerLink]="menu.routerLink"
          >
            <mat-icon class="menu-icon">{{ menu.icon }} </mat-icon>
            <span class="menu-title">{{ menu.title | translate }}</span>
          </a>
        </ng-template>
      </li>
      <ng-template #menuGroup>
        <button
          (click)="toggleExpendMenu(menu)"
          class="list-item-button"
          mat-list-item
        >
          <span>
            <mat-icon class="menu-icon">{{ menu.icon }} </mat-icon>
            <span class="menu-title">{{ menu.title | translate }}</span>
          </span>
          <mat-icon class="expand-icon">keyboard_arrow_down</mat-icon>
        </button>
      </ng-template>
      <ng-template #menuGroupIcon>
        <button
          [matTooltip]="menu.title | translate"
          matTooltipPosition="right"
          (click)="toggleExpendMenu(menu)"
          class="list-item-button"
          mat-list-item
        >
          <span>
            <mat-icon [ngClass]="{'menu-icon': !isMenuIcon}">{{ menu.icon }} </mat-icon>
            <span *ngIf="!isMenuIcon" class="menu-title">{{ menu.title | translate }}</span>
          </span>
        </button>
      </ng-template>
      <ng-container *ngIf="menu.children && menu.expanded">
        <ng-container
          *ngTemplateOutlet="
            navigation;
            context: { $implicit: { menuList: menu.children, isSubMenu: true } }
          "
        ></ng-container>
      </ng-container>
    </ng-container>
  </mat-nav-list>
</ng-template>

<!-- <mat-nav-list class="menu main-menu">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node mat-list-item *matTreeNodeDef="let node" matTreeNodePadding @subItem>
        <a
          mat-list-item
          routerLinkActive="active"
          (click)="onClickMenu()"
          [matTooltip]="node.name | translate"
          [matTooltipDisabled]="!isMenuIcon"
          [routerLink]="node.reference.routerLink"
        >
          <mat-icon>{{ node.reference.icon }} </mat-icon><span class="menu-title">{{ node.name | translate }}</span>
        </a>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-list-item matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="list-item-button">
          <span><mat-icon>{{ node.reference.icon }}</mat-icon><span class="menu-title">{{ node.name | translate }}</span></span>
          <mat-icon class="mat-icon-rtl-mirror" *ngIf="!isMenuIcon">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
      </mat-tree-node>
  </mat-tree>
</mat-nav-list> -->
