import { Directive, ElementRef, AfterViewInit, OnDestroy, HostListener, Input, NgZone} from '@angular/core';
import SmoothScrollbar from 'smooth-scrollbar';
import { Scrollbar } from 'smooth-scrollbar/interfaces';

/**
 * This directive is used to make table scrollable when the window innerwidth shrink to a certain breakpoint.
 * Some table has initialize position sticky to its cell which is not work as expected.
 * Use 'TableSmoothScrollDirective' to fixe those issues.
 * 
 * @see EnterpriseComponenthtml and EnterpriseComponentTs for reference.
 * @deprecated
 */
@Directive({
	selector: '[appTableSmoothScroll]'
})
export class TableSmoothScrollDirective implements AfterViewInit, OnDestroy {
	smoothScrollRef!: Scrollbar;
	stickyElements: HTMLElement[] = [];
	stickyLeftElements: HTMLElement[] = [];
	stickPaginator: any;
	offset: number = 0;
	space: number = 0;

	@Input() multipleSticky: boolean = false;
	@Input() reduceMultipleStickySpacing: number = 1;



	// pagination button
	// onClickNext: any;
	// onClickPrevious: any;
	// onClickShowFirst: any;
	// onClickShowLast: any;
	// onClickPageSize: any;
	
	constructor(
		private el: ElementRef<HTMLElement>,
		private _ngZone: NgZone
	) {}

	ngAfterViewInit(): void {

		this.smoothScrollRef = this._ngZone.runOutsideAngular(() => SmoothScrollbar.init(this.el.nativeElement, { continuousScrolling: true }));

		// this.stickyElements = Array.from(this.el.nativeElement.querySelectorAll<HTMLElement>('.mat-table-sticky.mat-table-sticky-border-elem-right'));
		// this.stickyElements = Array.from(this.el.nativeElement.querySelectorAll<HTMLElement>('.mat-table-sticky.mat-table-sticky-border-elem-left'));
		this.smoothScrollRef.addListener(res => {
			this.stickyElements = Array.from(this.el.nativeElement.querySelectorAll<HTMLElement>('.mat-table-sticky.mat-table-sticky-border-elem-right, .mat-table-sticky.mat-table-sticky-border-elem-right + .mat-table-sticky'));
			this.stickyLeftElements = Array.from(this.el.nativeElement.querySelectorAll<HTMLElement>('.mat-table-sticky.mat-table-sticky-border-elem-left'));
			// this.stickPaginator = this.el.nativeElement.querySelector('.mat-paginator-container');
			this.space = res.offset.x;
			for (const [index, stickyElement] of this.stickyElements.entries()) {
				let offset = this.space;
				if (this.multipleSticky && index % 2 == 0) {
					offset -= stickyElement.clientWidth - this.reduceMultipleStickySpacing;
				}
				stickyElement.style.right = -(offset + 1) + 'px';
			}

			for (const stickyLeftElement of this.stickyLeftElements) {
				stickyLeftElement.style.left = this.space + 'px'
			}
		});

		// // listen to button click next
		// this.onClickNext = fromEvent(this.el.nativeElement.querySelector('.mat-focus-indicator.mat-tooltip-trigger.mat-paginator-navigation-next.mat-icon-button.mat-button-base'), "click")
		// 	.subscribe(() => this.smoothScrollRef.scrollTo(-1, 0));

		// // listen to button click previous
		// this.onClickPrevious = fromEvent(this.el.nativeElement.querySelector('.mat-focus-indicator.mat-tooltip-trigger.mat-paginator-navigation-previous.mat-icon-button.mat-button-base'), "click")
		// 	.subscribe(() => this.smoothScrollRef.scrollTo(-1, 0));

		// // listen to button show first
		// this.onClickShowFirst = fromEvent(this.el.nativeElement.querySelector('.mat-focus-indicator.mat-tooltip-trigger.mat-paginator-navigation-first.mat-icon-button.mat-button-base'), "click")
		// 	.subscribe(() => this.smoothScrollRef.scrollTo(-1, 0));

		// // listen to button show last
		// this.onClickShowLast = fromEvent(this.el.nativeElement.querySelector('.mat-focus-indicator.mat-tooltip-trigger.mat-paginator-navigation-last.mat-icon-button.mat-button-base'), "click")
		// 	.subscribe(() => this.smoothScrollRef.scrollTo(-1, 0));

		// // // listen to div change page size
		// this.onClickPageSize = fromEvent(this.el.nativeElement.querySelector('.mat-form-field-wrapper'), "click")
		// 	.subscribe(() => this.smoothScrollRef.scrollTo(-1, 0));

	}

	onChangeTable() {
		this.smoothScrollRef.scrollTo(0, 0);
	}

	ngOnDestroy(): void {
		this.smoothScrollRef.destroy();
		// this.onClickNext.unsubscribe();
		// this.onClickPrevious.unsubscribe();
		// this.onClickShowFirst.unsubscribe();
		// this.onClickShowLast.unsubscribe();
		// this.onClickPageSize.unsubscribe();
	}

}