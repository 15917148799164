export const environment = {
  production: false,
  api_url: 'https://nssf-hos-api.myplgs.com/admin/api/v1',
  LIMIT_PRINT_CARD: 20,
  DEFAULT_USER_IMAGE: "/assets/images/default_profile.png",
  VERSION: "0.0.1",

  NSSF_REDIRECT_URI: 'https://nssf-hos-admin.myplgs.com/auth/callback',
  NSSF_AUTH_URL: 'https://accountuat.nssf.gov.kh/connect',
  NSSF_ADMIN_AUTH_CLIENT_ID: '2ADA5F1E-357D-401B-92A3-F817C567FEDF',
  NSSF_ADMIN_AUTH_CLIENT_SECRET: 'CBEAF593-951D-424B-8098-A7C8C66BFDFD',
  NSSF_CLIENT_CODE_VERIFIER: 'od38lK15WykzQ19B-jbwtKFDa9HF8oiY4u1H87elLbU',

  /** OAUTH2 */
  NSSF_LOGOUT_REDIRECT_URI: 'https://ben-nssf.myplgs.com/login',
  IS_ENABLE_OAUTH: true,
  IS_SYSTEM_TESTING: false
};

