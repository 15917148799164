import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fileSizeValidator } from 'src/app/helper/file-size-validator';
import { fileType } from 'src/app/helper/file-type-validator';

@Component({
  selector: 'app-img-upload-with-validate',
  templateUrl: './img-upload-with-validate.component.html',
  styleUrls: ['./img-upload-with-validate.component.scss']
})
export class ImgUploadWithValidateComponent  {

  public form: UntypedFormGroup;
  ref_doc_invalid_msg: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ImgUploadWithValidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: {
      upload: boolean,
      title: string,
      key: string
    }
  ) {
    this.form = this.formBuilder.group({
      ref: ['', [Validators.required, fileType('image/*'), fileSizeValidator(1)]]
    });
  }

  onFileChange() {
    this.ref_doc_invalid_msg = '';
  }

}
