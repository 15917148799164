import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { REJECT_TYPE } from 'src/app/models/enums/reject_type.enum';
import { STATUS } from 'src/app/models/enums/status.enum';

export enum RejectStatus {
  PHOTO = 'PHOTO', // 2
  IDCARD = 'IDCARD', // 3
  PHOTO_IDCARD = 'PHOTO_IDCARD', // 1
  DENIED = 'DENIED', // 4
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string = '';
  confirm_desc: string = '';
  confirm_type: string = '';
  CONFIRM_TYPE_ADVANCE: string = CONFIRM_TYPE.ADVANCE;
  CONFIRM_TYPE_NORMAL: string = CONFIRM_TYPE.NORMAL;
  formDataGroup: UntypedFormGroup;
  favorite_reject_type: string = '';
  is_no_action: boolean = false;

  STATUS: any = STATUS;
  REJECT_TYPE: any = REJECT_TYPE;

  member_photo: boolean = false;
  idcard_photo: boolean = false;

  rejectData: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = this.data.title;
    this.confirm_desc = this.data.confirm_desc;
    this.confirm_type = this.data.confirm_type;
    this.is_no_action = this.data.is_no_action;
    this.formDataGroup = this.formBuilder.group({
      reason: [
        '',
        this.data.is_reject && this.favorite_reject_type == REJECT_TYPE.REJECT
          ? Validators.required
          : '',
      ],
      reject_option: ['', this.data.is_reject ? Validators.required : null],
    });
  }

  ngOnInit(): void {}

  returnMemberPhoto(event: any) {
    this.member_photo = event?.checked;
    this.defineRejectData();
  }

  returnIdCardPhoto(event: any) {
    this.idcard_photo = event?.checked;
    this.defineRejectData();
  }

  onRejectTypeChange(event: any) {
    this.favorite_reject_type = event.value;

    if (event.value == REJECT_TYPE.RETURN) {
      this.formDataGroup.controls['reason'].reset();
    }

    this.member_photo = false;
    this.idcard_photo = false;

    this.defineRejectData();
  }

  isDisabled() {
    return this.favorite_reject_type == REJECT_TYPE.REJECT
      ? this.formDataGroup.invalid
      : this.data.isReturnOption
      ? !this.member_photo && !this.idcard_photo
      : this.formDataGroup.invalid;
  }

  defineRejectData() {
    let note: string = this.formDataGroup.controls['reason'].value;
    this.rejectData = {
      reason: note ? note : 'Please correct information',
      code: this.getRejectID(),
    };
  }

  getRejectID() {
    let id: RejectStatus;
    this.favorite_reject_type =
      this.formDataGroup.controls['reject_option'].value;
    if (!this.data.isReturnOption) {
      return this.favorite_reject_type;
    }
    switch (this.favorite_reject_type) {
      case REJECT_TYPE.RETURN:
        if (this.member_photo && this.idcard_photo) {
          id = RejectStatus.PHOTO_IDCARD;
        } else if (this.member_photo) {
          id = RejectStatus.PHOTO;
        } else if (this.idcard_photo) {
          id = RejectStatus.IDCARD;
        }
        break;
      default:
        id = RejectStatus.DENIED;
    }
    return id!;
  }
}

export enum CONFIRM_TYPE {
  ADVANCE = 'ADVANCE',
  NORMAL = 'NORMAL',
}
