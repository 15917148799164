import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent {

  @Input() progress: any;
  @Input() accept: any;
  @Input() allowFullHeight: boolean = false;
  onChange: ((file: any) => void) | undefined;
  public file: File | null | undefined;
  public imgSrc: any;
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    this.imgSrc = this.file = null;
    const file = event && event.item(0);
    if (this.onChange) {
      this.onChange(file);
    }
    this.preview(file);
    this.file = file;
  }

  constructor(private host: ElementRef<HTMLInputElement>) {
  }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
  }

  private preview(file: any) {
    const mimeType = file.type;

    /* if (mimeType == "application/pdf") {
      return this.imgSrc = "../../../../../assets/img/pdf-icon.svg"
    } else  */
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imgSrc = reader.result;
    };
  }

}
