import { PERMISSION, SECTION } from './permission.enum';
export const ROUTE_PERMISSIONS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [PERMISSION.READ_DASHBOARD],
  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  NSSF_USER: [
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  HOSPITAL_REQUEST: [
    PERMISSION.READ_HOS_REQ,
    PERMISSION.ALLOW_REGISTER_HOS_REQ,
    PERMISSION.ALLOW_APPROVE_HOS_REQ,
    PERMISSION.ALLOW_RETURN_HOS_REQ,
    PERMISSION.ALLOW_REJECT_HOS_REQ ,
    PERMISSION.ALLOW_EDIT_HOS_REQ,

  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL
  // -----------------------------------------------------
  HOSPITAL: [
    PERMISSION.READ_HOSPITAL,
    PERMISSION.ALLOW_EDIT_HOSPITAL,
    PERMISSION.ALLOW_CREATE_HOS_CONTRACT,

  ],
 
  // -----------------------------------------------------
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    SECTION.SETTING,
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],

}
export const EXTENDED_ROUTE_PMS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [PERMISSION.READ_DASHBOARD],
  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  NSSF_USER: [
    // SECTION.NSSF_USER,
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],
   // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  HOSPITAL_REQUEST: [
    PERMISSION.READ_HOS_REQ,
    PERMISSION.ALLOW_REGISTER_HOS_REQ,
    PERMISSION.ALLOW_APPROVE_HOS_REQ,
    PERMISSION.ALLOW_RETURN_HOS_REQ,
    PERMISSION.ALLOW_REJECT_HOS_REQ ,
    PERMISSION.ALLOW_EDIT_HOS_REQ,

  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL
  // -----------------------------------------------------
  HOSPITAL: [
    PERMISSION.READ_HOSPITAL,
    PERMISSION.ALLOW_CREATE_HOS_CONTRACT,
    PERMISSION.ALLOW_EDIT_HOSPITAL,

  ],

    // -----------------------------------------------------
  // MAIN: PHARMACY REQUEST
  // -----------------------------------------------------
  PHARMACY_REQUEST: [
    PERMISSION.READ_PHARMACY_REQ,
    PERMISSION.ALLOW_REGISTER_PHARMACY_REQ,
    PERMISSION.ALLOW_APPROVE_PHARMACY_REQ,
    PERMISSION.ALLOW_RETURN_PHARMACY_REQ,
    PERMISSION.ALLOW_REJECT_PHARMACY_REQ ,
    PERMISSION.ALLOW_EDIT_PHARMACY_REQ,

  ],
  // -----------------------------------------------------
  // MAIN: PHARMACY
  // -----------------------------------------------------
  PHARMACY: [
    PERMISSION.READ_PHARMACY,
    PERMISSION.ALLOW_EDIT_PHARMACY,
    PERMISSION.ALLOW_CREATE_PHARMACT_CONTRACT,

  ],
  
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],
}
export const ROUTE_PMS_JSON = [
  {
    name: SECTION.DASHBOARD,
    key: 'DASHBOARD',
    icon: 'dashboard',
    children: [
      {
        name: SECTION.DASHBOARD_WORK,
        path: '/dashboard',
        pms_keys: [PERMISSION.READ_DASHBOARD],
      },
    ],
  },
  {
    name: SECTION.NSSF_USER,
    key: 'NSSF_USER',
    icon: 'manage_accounts',
    children: [
      {
        name: SECTION.NSSF_USER,
        path: '/user-admin',
        pms_keys: EXTENDED_ROUTE_PMS.NSSF_USER,
      },
    ],
  },
  {
    name: SECTION.HOSPITAL,
    key: 'HOSPITAL_REQUEST',
    icon: 'local_hospital',
    children: [
      {
        name: SECTION.HOSPITAL_REQUEST,
        path: '/hospital/hospital-request',
        pms_keys: EXTENDED_ROUTE_PMS.HOSPITAL_REQUEST,
      },
      {
        name: SECTION.HOSPITAL_RESULT,
        path: '/hospital/hospital-result',
        pms_keys: EXTENDED_ROUTE_PMS.HOSPITAL,
      },
    ],
  },
  {
    name: SECTION.PHARMACY,
    key: 'HOSPITAL_REQUEST',
    icon: 'local_hospital',
    children: [
      {
        name: SECTION.PHARMACY_REQUEST,
        path: '/hospital/hospital-request',
        pms_keys: EXTENDED_ROUTE_PMS.PHARMACY_REQUEST,
      },
      {
        name: SECTION.HOSPITAL_RESULT,
        path: '/hospital/hospital-result',
        pms_keys: EXTENDED_ROUTE_PMS.PHARMACY,
      },
    ],
  },

  {
    name: SECTION.SETTING,
    key: 'SETTING',
    icon: 'settings',
    children: [
      {
        name: SECTION.SETTING,
        path: '/organization',
        pms_keys: EXTENDED_ROUTE_PMS.SETTING,
      },
    ],
  },
]


