import { LocalStorageEnum } from './../models/enums/local-storage.enum';
import { LocalStorageService } from './../services/local-storage.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service'

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {
	// constructor(private authService: AuthService,
	//   private router: Router,
	//   private localStorageService: LocalStorageService) {
	// }

	// canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

	//   if (this.authService.authStatus) {
	//     // let data: any = this.localStorageService.getDecryption(LocalStorageEnum.user);
	//     // let currentUser: any = JSON.parse(data ? data : '');

	//     // if (route.data.roles && route.data.roles.indexOf(currentUser.role.code) === -1) {
	//     //   this.router.navigate(['login']);
	//     //   return false;
	//     // }

	//     return true;
	//   }
	//   else {
	//     return !this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
	//   }
	// }


	constructor(private router: Router, private authService: AuthService, private localStorageService: LocalStorageService) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		if (this.authService.authStatus) {
			const routePermissions = next.data.permissions as Array<string>;
			const permissions = this.localStorageService.getArray(LocalStorageEnum.permissions);
			if (!routePermissions || routePermissions.length === 0 || routePermissions.filter(p => permissions.includes(p)).length > 0) {
				return true;
			} else {
				return false;
			}

		} else {
			// return !this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
			return !this.router.navigate(['']);
		}

	}
}
