export enum PERMISSION {
   // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  READ_DASHBOARD = 'READ_DASHBOARD',
  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  READ_NSSF_USER = 'READ_NSSF_USER',
  ALLOW_CREATE_NSSF_USER = 'ALLOW_CREATE_NSSF_USER',
  ALLOW_EDIT_NSSF_USER = 'ALLOW_EDIT_NSSF_USER',
  ALLOW_DISABLE_NSSF_USER = 'ALLOW_DISABLE_NSSF_USER',

  // -----------------------------------------------------
  // MAIN: SETTINGS
  // -----------------------------------------------------
  READ_SETTING = 'READ_SETTING',
  ALLOW_CREATE_SETTING = 'ALLOW_CREATE_SETTING',
  ALLOW_EDIT_SETTING = 'ALLOW_EDIT_SETTING',
  ALLOW_DISABLE_SETTING = 'ALLOW_DISABLE_SETTING',

  // MAIN: HOSTPITAL
  // -----------------------------------------------------
  READ_HOSPITAL = "READ_HOSPITAL",
  ALLOW_EDIT_HOSPITAL = "ALLOW_EDIT_HOSPITAL",
  ALLOW_CREATE_HOS_CONTRACT = "ALLOW_CREATE_HOS_CONTRACT",
  // MAIN: HOSTPITAL REQUEST
  // -----------------------------------------------------
  READ_HOS_REQ="READ_HOS_REQ",
  ALLOW_REGISTER_HOS_REQ="ALLOW_REGISTER_HOS_REQ",
  ALLOW_APPROVE_HOS_REQ = "ALLOW_APPROVE_HOS_REQ",
  ALLOW_RETURN_HOS_REQ = "ALLOW_RETURN_HOS_REQ",
  ALLOW_EDIT_HOS_REQ = "ALLOW_EDIT_HOS_REQ",
  ALLOW_REJECT_HOS_REQ="ALLOW_REJECT_HOS_REQ",

  // MAIN: PHARMACY
  // -----------------------------------------------------
  READ_PHARMACY = "READ_PHARMACY",
  ALLOW_EDIT_PHARMACY = "ALLOW_EDIT_PHARMACY",
  ALLOW_CREATE_PHARMACT_CONTRACT = "ALLOW_CREATE_PHARMACT_CONTRACT",

  // MAIN: PHARMACY REQUEST
  // -----------------------------------------------------
  READ_PHARMACY_REQ = "READ_PHARMACY_REQ",
  ALLOW_REGISTER_PHARMACY_REQ = "ALLOW_REGISTER_PHARMACY_REQ",
  ALLOW_APPROVE_PHARMACY_REQ = "ALLOW_APPROVE_PHARMACY_REQ",
  ALLOW_RETURN_PHARMACY_REQ = "ALLOW_RETURN_PHARMACY_REQ",
  ALLOW_REJECT_PHARMACY_REQ = "ALLOW_REJECT_PHARMACY_REQ",
  ALLOW_EDIT_PHARMACY_REQ = "ALLOW_EDIT_PHARMACY_REQ",




  //   USER_CREATE = "USER_CREATE",
  //   USER_UPDATE = "USER_UPDATE",
  //   // USER_DELETE = "USER_DELETE",
  //   USER_READ = "USER_READ",
  //   USER_DISABLE = "USER_DISABLE",
  //   ROLE_CREATE = "ROLE_CREATE",
  //   ROLE_UPDATE = "ROLE_UPDATE",
  //   ROLE_DELETE = "ROLE_DELETE",
  //   ROLE_READ = "ROLE_READ",
  //   ENTERPRISE_USER_CREATE = "ENTERPRISE_USER_CREATE",
  //   ENTERPRISE_USER_UPDATE = "ENTERPRISE_USER_UPDATE",
  //   ENTERPRISE_USER_DELETE = "ENTERPRISE_USER_DELETE",
  //   ENTERPRISE_USER_READ = "ENTERPRISE_USER_READ",
  //   ENTERPRISE_CREATE = "ENTERPRISE_CREATE",
  //   ENTERPRISE_UPDATE = "ENTERPRISE_UPDATE",
  //   ENTERPRISE_DELETE = "ENTERPRISE_DELETE",
  //   ENTERPRISE_READ = "ENTERPRISE_READ",
  //   CONTRIBUTE_CREATE = "CONTRIBUTE_CREATE",
  //   CONTRIBUTE_UPDATE = "CONTRIBUTE_UPDATE",
  //   CONTRIBUTE_DELETE = "CONTRIBUTE_DELETE",
  //   CONTRIBUTE_READ = "CONTRIBUTE_READ",
  //   CONTRIBUTE_DETAIL_CREATE = "CONTRIBUTE_DETAIL_CREATE",
  //   CONTRIBUTE_DETAIL_UPDATE = "CONTRIBUTE_DETAIL_UPDATE",
  //   CONTRIBUTE_DETAIL_DELETE = "CONTRIBUTE_DETAIL_DELETE",
  //   CONTRIBUTE_DETAIL_READ = "CONTRIBUTE_DETAIL_READ",
  //   CONTRIBUTE_FEE_CREATE = "CONTRIBUTE_FEE_CREATE",
  //   CONTRIBUTE_FEE_UPDATE = "CONTRIBUTE_FEE_UPDATE",
  //   CONTRIBUTE_FEE_DELETE = "CONTRIBUTE_FEE_DELETE",
  //   CONTRIBUTE_FEE_READ = "CONTRIBUTE_FEE_READ",
  //   BENEFICIARY_CREATE = "BENEFICIARY_CREATE",
  //   BENEFICIARY_UPDATE = "BENEFICIARY_UPDATE",
  //   BENEFICIARY_DELETE = "BENEFICIARY_DELETE",
  //   BENEFICIARY_READ = "BENEFICIARY_READ",
  //   BENEFICIARY_TYPE_CREATE = "BENEFICIARY_TYPE_CREATE",
  //   BENEFICIARY_TYPE_UPDATE = "BENEFICIARY_TYPE_UPDATE",
  //   BENEFICIARY_TYPE_DELETE = "BENEFICIARY_TYPE_DELETE",
  //   BENEFICIARY_TYPE_READ = "BENEFICIARY_TYPE_READ",
  //   ADDRESS_CREATE = "ADDRESS_CREATE",
  //   ADDRESS_UPDATE = "ADDRESS_UPDATE",
  //   ADDRESS_DELETE = "ADDRESS_DELETE",
  //   ADDRESS_READ = "ADDRESS_READ",
  //   DEPARTMENT_CREATE = "DEPARTMENT_CREATE",
  //   DEPARTMENT_UPDATE = "DEPARTMENT_UPDATE",
  //   DEPARTMENT_DELETE = "DEPARTMENT_DELETE",
  //   DEPARTMENT_READ = "DEPARTMENT_READ",
  //   MINISTRY_CREATE = "MINISTRY_CREATE",
  //   MINISTRY_UPDATE = "MINISTRY_UPDATE",
  //   MINISTRY_DELETE = "MINISTRY_DELETE",
  //   MINISTRY_READ = "MINISTRY_READ",
  //   CURRENCY_CREATE = "CURRENCY_CREATE",
  //   CURRENCY_UPDATE = "CURRENCY_UPDATE",
  //   CURRENCY_DELETE = "CURRENCY_DELETE",
  //   CURRENCY_READ = "CURRENCY_READ",
  //   DISCOUNT_CREATE = "DISCOUNT_CREATE",
  //   DISCOUNT_UPDATE = "DISCOUNT_UPDATE",
  //   DISCOUNT_DELETE = "DISCOUNT_DELETE",
  //   DISCOUNT_READ = "DISCOUNT_READ",
  //   EXCHANGE_RATE_CREATE = "EXCHANGE_RATE_CREATE",
  //   EXCHANGE_RATE_UPDATE = "EXCHANGE_RATE_UPDATE",
  //   EXCHANGE_RATE_DELETE = "EXCHANGE_RATE_DELETE",
  //   EXCHANGE_RATE_READ = "EXCHANGE_RATE_READ",
  //   MARITAL_STATUS_CREATE = "MARITAL_STATUS_CREATE",
  //   MARITAL_STATUS_UPDATE = "MARITAL_STATUS_UPDATE",
  //   MARITAL_STATUS_DELETE = "MARITAL_STATUS_DELETE",
  //   MARITAL_STATUS_READ = "MARITAL_STATUS_READ",
  //   CARD_TYPE_CREATE = "CARD_TYPE_CREATE",
  //   CARD_TYPE_UPDATE = "CARD_TYPE_UPDATE",
  //   CARD_TYPE_DELETE = "CARD_TYPE_DELETE",
  //   CARD_TYPE_READ = "CARD_TYPE_READ",
  //   BUSINESS_TYPE_CREATE = "BUSINESS_TYPE_CREATE",
  //   BUSINESS_TYPE_UPDATE = "BUSINESS_TYPE_UPDATE",
  //   BUSINESS_TYPE_DELETE = "BUSINESS_TYPE_DELETE",
  //   BUSINESS_TYPE_READ = "BUSINESS_TYPE_READ",
  //   ENTERPRISE_TYPE_CREATE = "ENTERPRISE_TYPE_CREATE",
  //   ENTERPRISE_TYPE_UPDATE = "ENTERPRISE_TYPE_UPDATE",
  //   ENTERPRISE_TYPE_DELETE = "ENTERPRISE_TYPE_DELETE",
  //   ENTERPRISE_TYPE_READ = "ENTERPRISE_TYPE_READ",
  //   ENTERPRISE_EXTERNAL_TYPE_CREATE = "ENTERPRISE_EXTERNAL_TYPE_CREATE",
  //   ENTERPRISE_EXTERNAL_TYPE_UPDATE = "ENTERPRISE_EXTERNAL_TYPE_UPDATE",
  //   ENTERPRISE_EXTERNAL_TYPE_DELETE = "ENTERPRISE_EXTERNAL_TYPE_DELETE",
  //   ENTERPRISE_EXTERNAL_TYPE_READ = "ENTERPRISE_EXTERNAL_TYPE_READ",
  //   PERMISSION_CREATE = "PERMISSION_CREATE",
  //   PERMISSION_UPDATE = "PERMISSION_UPDATE",
  //   PERMISSION_DELETE = "PERMISSION_DELETE",
  //   PERMISSION_READ = "PERMISSION_READ",
  //   INFORMAL_BENEFICIARY_CREATE = "INFORMAL_BENEFICIARY_CREATE",
  //   INFORMAL_BENEFICIARY_UPDATE = "INFORMAL_BENEFICIARY_UPDATE",
  //   INFORMAL_BENEFICIARY_DELETE = "INFORMAL_BENEFICIARY_DELETE",
  //   INFORMAL_BENEFICIARY_READ = "INFORMAL_BENEFICIARY_READ",
  //   VOLUNTARY_BENEFICIARY_CREATE = "VOLUNTARY_BENEFICIARY_CREATE",
  //   VOLUNTARY_BENEFICIARY_UPDATE = "VOLUNTARY_BENEFICIARY_UPDATE",
  //   VOLUNTARY_BENEFICIARY_DELETE = "VOLUNTARY_BENEFICIARY_DELETE",
  //   VOLUNTARY_BENEFICIARY_READ = "VOLUNTARY_BENEFICIARY_READ",
}
export enum SECTION {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD = 'menu.dashboard',
  DASHBOARD_WORK = 'menu.dashboard',

    // MAIN: NSSF USER
    // -----------------------------------------------------
    NSSF_USER = 'menu.user_admin',
    // -----------------------------------------------------
    // MAIN: SETTINGS
    // -----------------------------------------------------
    SETTING = 'menu.setting',
     // MAIN: VICTIM
    // -----------------------------------------------------
    VICTIM_LIST = 'menu.victim_list',
    // MAIN: HOSPITAL REQUEST
    // -----------------------------------------------------
    HOSPITAL_REQUEST = 'setting.hospital.hospital_request',
    HOSPITAL = 'setting.hospital.hospital',
    // MAIN: HOSPITAL  REQUEST RESULT
    // -----------------------------------------------------
    HOSPITAL_RESULT = 'setting.hospital.hospital_result',

    // MAIN: PHARMACY REQUEST
    // -----------------------------------------------------
    PHARMACY_REQUEST = 'setting.hospital.hospital_request',
    PHARMACY = 'setting.pharmacy.title',
    // MAIN: PHARMACY  REQUEST RESULT
    // -----------------------------------------------------
    PHARMACY_RESULT = 'setting.pharmacy.result',

}