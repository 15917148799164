<h2 mat-dialog-title>{{ title | translate }}</h2>

<!-- ================== advance confirm dialog content ================= -->
<mat-dialog-content
  class="custom-dialog-content"
  *ngIf="confirm_type == CONFIRM_TYPE_ADVANCE"
>
  <p class="desc">{{ confirm_desc | translate }}</p>
  <form [formGroup]="formDataGroup" #form="ngForm" *ngIf="data?.is_reject">
    <div class="form-row">
      <div class="form-group col-md-12">
        <mat-radio-group
          formControlName="reject_option"
          (change)="onRejectTypeChange($event)"
        >
          <!-- Reject -->
          <mat-radio-button [value]="REJECT_TYPE.REJECT">{{
            "dialog.confirm.reject_reg" | translate
          }}</mat-radio-button>

          <textarea
            *ngIf="
              favorite_reject_type == REJECT_TYPE.REJECT && data.isReturnOption
            "
            type="text"
            class="form-control"
            placeholder="{{ 'dialog.confirm.reason' | translate }}"
            [ngClass]="{
              'is-invalid':
                (formDataGroup.get('reason')?.invalid &&
                  formDataGroup.get('reason')?.touched) ||
                (formDataGroup.get('reason')?.invalid && form.submitted)
            }"
            formControlName="reason"
            required
          >
          </textarea>
          <!-- Return -->
          <mat-radio-button [value]="REJECT_TYPE.RETURN">{{
            "dialog.confirm.return" | translate
          }}</mat-radio-button>
          <ng-container
            *ngIf="
              favorite_reject_type == REJECT_TYPE.RETURN && data.isReturnOption
            "
          >
            <section class="return">
              <mat-checkbox (change)="returnMemberPhoto($event)">
                {{ "dialog.confirm.return_member_photo" | translate }}
              </mat-checkbox>
              <mat-checkbox (change)="returnIdCardPhoto($event)">
                {{ "dialog.confirm.return_idcard_photo" | translate }}
              </mat-checkbox>
            </section>
          </ng-container>
        </mat-radio-group>
        <textarea
          *ngIf="!data.isReturnOption"
          type="text"
          class="form-control"
          placeholder="{{ 'dialog.confirm.reason' | translate }}"
          [ngClass]="{
            'is-invalid':
              (formDataGroup.get('reason')?.invalid &&
                formDataGroup.get('reason')?.touched) ||
              (formDataGroup.get('reason')?.invalid && form.submitted)
          }"
          formControlName="reason"
          required
        >
        </textarea>
      </div>
    </div>
  </form>
</mat-dialog-content>

<!-- ================== normal confirm dialog content ================== -->
<mat-dialog-content
  class="custom-dialog-content"
  *ngIf="confirm_type == CONFIRM_TYPE_NORMAL"
>
  <p class="desc">
    <mat-icon *ngIf="data.type_icon">{{ data.type_icon }}</mat-icon>
    {{ confirm_desc | translate }}
  </p>
</mat-dialog-content>

<!-- ================== normal reject with just field reason ================== -->
<mat-dialog-content class="custom-dialog-content" *ngIf="!confirm_type">
  <p class="desc">
    <mat-icon
      class="icon-status"
      [ngClass]="{ error: data.error }"
      *ngIf="data.type_icon"
      >{{ data.type_icon }}</mat-icon
    >{{ confirm_desc | translate }}
  </p>
  <form [formGroup]="formDataGroup" #form="ngForm" *ngIf="data?.is_reject">
    <div class="form-row">
      <div class="m-form form-group col-md-12">
        <textarea
          type="text"
          class="form-control"
          style="height: auto; margin-left: unset; width: 100%"
          rows="3"
          [placeholder]="'dialog.confirm.reason' | translate"
          [ngClass]="{
            'is-invalid':
              (formDataGroup.get('reason')?.invalid &&
                formDataGroup.get('reason')?.touched) ||
              (formDataGroup.get('reason')?.invalid && form.submitted)
          }"
          formControlName="reason"
          required
        >
        </textarea>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!is_no_action">
  <!-- Close Button -->
  <button mat-button mat-dialog-close class="m-button-outlined">
    <mat-icon style="transform: rotateY(180deg)">logout</mat-icon
    >{{ "button.close" | translate }}
  </button>

  <!-- Action Button(s) -->
  <ng-container *ngIf="confirm_type == CONFIRM_TYPE_ADVANCE">
    <button
      mat-raised-button
      class="m-button-primary pr-1"
      [mat-dialog-close]="true"
      *ngIf="data?.is_approve; else nextButton"
    >
      {{ "button.confirm" | translate
      }}<mat-icon class="ml-1">arrow_forward</mat-icon>
    </button>
    <ng-template #nextButton>
      <button
        mat-raised-button
        class="m-button-primary pr-1"
        [mat-dialog-close]="rejectData"
        *ngIf="data?.is_reject"
        [disabled]="isDisabled()"
      >
        {{ "button.confirm" | translate
        }}<mat-icon class="ml-1">arrow_forward</mat-icon>
      </button>
    </ng-template>
  </ng-container>

  <!-- Button(s) for normal confirm -->
  <ng-container *ngIf="confirm_type == CONFIRM_TYPE_NORMAL">
    <button
      mat-raised-button
      class="m-button-primary pr-1"
      [mat-dialog-close]="true"
    >
      {{ "button.confirm" | translate
      }}<mat-icon class="ml-1">arrow_forward</mat-icon>
    </button>
  </ng-container>

  <!-- Button(s) for when confirm_type is undefined -->
  <ng-container *ngIf="!confirm_type">
    <button
      mat-raised-button
      class="m-button-primary pr-1"
      [mat-dialog-close]="formDataGroup.controls['reason'].value"
      *ngIf="data?.is_reject"
      [disabled]="formDataGroup.get('reason')?.invalid"
    >
      <!-- [ngClass]="{'reject': formDataGroup.get('reason')?.valid, 'btn-disabled': formDataGroup.get('reason')?.invalid}" -->
      {{ "button.confirm" | translate
      }}<mat-icon class="ml-1">arrow_forward</mat-icon>
    </button>
  </ng-container>
</mat-dialog-actions>
