import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DatePickerLocaleComponent } from './date-picker-locale.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DatePickerLocaleComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatMomentDateModule,
    MatButtonModule,
    MatDatepickerModule
  ],
  exports: [
    DatePickerLocaleComponent
  ]
})
export class DatePickerLocaleModule { }
