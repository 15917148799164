<div class="dialog-header">
  <div class="dialog-header">
    <p mat-dialog-title class="pl-0">
      {{
        data?.title
          ? ("button.add_img" | translate)
          : ("button.change_img" | translate)
      }}
    </p>
  </div>
</div>

<mat-dialog-content>
  <form *ngIf="form" [formGroup]="form">
    <!-- <label>{{'general.logo' | translate}}{{'contribute.enterprise' | translate}}</label> -->
    <app-file-upload
      formControlName="ref"
      (change)="onFileChange()"
      [allowFullHeight]="true"
      ngDefaultControl
      name="ref"
      accept="image/*"
      [ngClass]="{ error: form.touched && form.get('ref')?.errors }"
    ></app-file-upload>
    <ng-container *ngIf="form.get('ref')?.errors">
      <div class="error-container">
        <mat-error *ngIf="form.get('ref')?.errors?.required"
          >{{ "general.logo" | translate
          }}{{ "contribute.enterprise" | translate }}
          {{ "validation.required" | translate }}</mat-error
        >
        <mat-error *ngIf="form.get('ref')?.errors?.fileType">{{
          "message_dialog.wrong_file_type" | translate
        }}</mat-error>
        <mat-error *ngIf="ref_doc_invalid_msg">{{
          ref_doc_invalid_msg
        }}</mat-error>
        <mat-error
          *ngIf="
            !form.get('ref')?.errors?.fileType &&
            form.get('ref')?.errors?.fileSizeValidator
          "
          >{{ "message_dialog.max_file_size" | translate }} 1MB</mat-error
        >
      </div>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="form">
  <button
    mat-button
    mat-dialog-close
    tabindex="-1"
    class="m-button-outlined s-md set-w"
  >
    <mat-icon style="transform: rotateY(180deg)">logout</mat-icon
    >{{ "button.close" | translate }}
  </button>
  <button
    mat-raised-button
    class="m-button-primary s-md approve"
    [disabled]="form.invalid"
    [mat-dialog-close]="form.get('ref')?.value"
  >
    <mat-icon>check</mat-icon
    >{{
      data?.title
        ? ("button.add_img" | translate)
        : ("button.change_img" | translate)
    }}
  </button>
</mat-dialog-actions>
