import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { CommonResponse } from '../models/responses/common-response';
import { IDepartmentResponse } from '../models/responses/department-response';
import { IDepartment } from '../models/department';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private requestService: RequestService) {}

  getAllDepartment(
    page: number,
    count: number,
    search: string = '',
    ministry?: string
  ): Observable<IDepartmentResponse> {
    // @ts-ignore
    return this.requestService.getJSON<IDepartmentResponse>(
      `/nssf-department`,
      {
        data: Object.assign({ page, count, search, ministry }),
        option: {
          is_loading: true,
        },
      }
    );
  }

  getDepartmentWithoutPagination(): Observable<
    CommonResponse<{ departments: IDepartment[] }>
  > {
    return this.requestService.getJSON<{ departments: IDepartment[] }>(
      `/nssf-department`,
      {
        data: {},
        option: {
          is_loading: true,
        },
      }
    );
  }

  getDepartmentID(id: string): Observable<CommonResponse<IDepartment>> {
    return this.requestService.getJSON<IDepartment>(`/nssf-department/${id}`, {
      data: {},
      option: {
        is_loading: true,
      },
    });
  }

  createDepartment(data: any): Observable<CommonResponse<IDepartment>> {
    return this.requestService.postJSON<CommonResponse<IDepartment>>(
      `/nssf-department/create`,
      {
        data,
        option: {
          is_loading: true,
        },
      },
      true
    );
  }

  updateDepartment(
    id: string,
    data: any
  ): Observable<CommonResponse<IDepartment>> {
    return this.requestService.putJSON<any>(
      `/nssf-department/update/${id}`,
      {
        data,
        option: {
          is_loading: true,
        },
      },
      true
    );
  }

  deleteDepartment(id: string) {
    return this.requestService.deleteJSON<any>(`/nssf-department/${id}`, {
      data: {},
      option: {
        is_loading: true,
      },
    });
  }

  activeToogle(id: string, data: any): Observable<any> {
    const path = `/nssf-department/${id}`;
    return this.requestService.putJSON<any>(path, {
      data,
      option: {
        is_loading: true,
      },
    });
  }
}
