import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmoothScrollbarComponent } from './smooth-scrollbar.component';



@NgModule({
  declarations: [
    SmoothScrollbarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SmoothScrollbarComponent
  ]
})
export class SmoothScrollbarModule { }
