import { SnackbarMessageComponent } from 'src/app/components/shares/snackbar-message/snackbar-message.component';
import { PipeModule } from 'src/app/components/shares/pipe/pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SmoothScrollModule } from 'src/app/directives/smooth-scroll/smooth-scroll.module';
import { NumberFormatPipe } from './pipe/number-format.pipe';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImgUploadWithValidateComponent } from './img-upload-with-validate/img-upload-with-validate.component';
import { MatMenuModule } from '@angular/material/menu';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { CustomLoadingComponent } from './custom-loading/custom-loading.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { SafePipe } from './pipe/safe.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NotificationIconComponent } from './notification-icon/notification-icon.component';
import { MessageComponent } from './message/message.component';
import { EmptyListModule } from './empty-list/empty-list.module';
import { NgxMaskModule } from 'ngx-mask';
import { MatChipsModule } from '@angular/material/chips';
import { DatePickerLocaleModule } from './date-picker-locale/date-picker-locale.module';
import { SelectModule } from './select/select.module';
import { FullFeedbackAlertModule } from './full-feedback-alert/full-feedback-alert.module';
import { FileCardModule } from './file-card/file-card.module';
import { PaginatorHidePageInfoModule } from 'src/app/directives/paginator-hide-page-info/paginator-hide-page-info.module';
import { ConfirmDialogButtonComponent } from './confirm-dialog-button/confirm-dialog-button.component';
import { UpdateProfileDialogComponent } from './update-profile-dialog/update-profile-dialog.component';
import { FileDragDropModule } from './file-drag-drop/file-drag-drop.module';
import { AssignDialogComponent } from './assign-dialog/assign-dialog.component';
import { FileViewerDialogComponent } from './file-viewer-dialog/file-viewer-dialog.component';
import { EnterpriseLogoDialogComponent } from './enterprise-logo-dialog/enterprise-logo-dialog.component';
import { TrimInputModule } from './trim-input/trim-input.module';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CustomLoadingComponent,
    ImageViewerComponent,
    ImgUploadWithValidateComponent,
    FileUploadComponent,
    NumberFormatPipe,
    PdfViewerComponent,
    SafePipe,
    NotificationIconComponent,
    MessageComponent,
    ConfirmDialogButtonComponent,
    UpdateProfileDialogComponent,
    SnackbarMessageComponent,
    AssignDialogComponent,
    FileViewerDialogComponent,
    EnterpriseLogoDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    FullFeedbackAlertModule,
    FormsModule,
    MatMenuModule,
    PaginatorHidePageInfoModule,
    MatTableModule,
    FileCardModule,
    MatPaginatorModule,
    DatePickerLocaleModule,
    MatChipsModule,
    SelectModule,
    TextFieldModule,
    EmptyListModule,
    SmoothScrollModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    FileDragDropModule,
    MatTooltipModule,
    PipeModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forChild(),
    TrimInputModule
  ],
  exports: [
    NumberFormatPipe,
    ConfirmDialogComponent,
    SafePipe,
    NotificationIconComponent
  ],
})
export class ShareModule {}
