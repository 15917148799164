import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-loading',
  templateUrl: './custom-loading.component.html',
  styleUrls: ['./custom-loading.component.scss']
})
export class CustomLoadingComponent implements OnInit {

  is_loading: boolean = false;

  @Input() set show(value: boolean) {
    this.is_loading = value;
  };

  constructor() { }

  ngOnInit(): void {
  }

}
