import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SnackbarMessageComponent } from '../snackbar-message/snackbar-message.component';

@Component({
	selector: 'app-enterprise-logo-dialog',
	templateUrl: './enterprise-logo-dialog.component.html',
	styleUrls: ['./enterprise-logo-dialog.component.scss']
})
export class EnterpriseLogoDialogComponent implements OnInit {

	filename!: string;
	photoSource!: SafeResourceUrl;
	photo: UntypedFormControl = new UntypedFormControl(null, Validators.required);

	constructor(
		private snackbar: MatSnackBar,
		private sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA) public data?: {
			upload: boolean,
			logo_url: string,
			title: string,
			key: string
		}
	) {
		if (data?.upload) this.photoSource = data.logo_url;
	}

	ngOnInit(): void {
	}

	onFileChange(e: File): void {
		this.filename = e.name;
		this.onFile(e);
	}

	onFile(file: File): void {
		if (!/image\/./.test(file.type)) {
			// FILE UNSUPPORTED
			this.throwError("general.file_unsupported");
			return;
		}
		this.photo.setValue(file);
		this.readFile(file);
	}

	readFile(file: File): void {
		const reader = new FileReader();
		reader.addEventListener("load", e => {
			this.photoSource = this.sanitizer.bypassSecurityTrustResourceUrl(e.target!.result as string);
		});
		reader.readAsDataURL(file);
	}

	throwError(msg: string): void {
		this.snackbar.openFromComponent(SnackbarMessageComponent, {
			data: {
				message: msg
			},
			duration: 2000,
			panelClass: 'app-error'
		})
	}
}
