<div class="document-card {{ rowClass }}">
  <div class="col-9 d-flex align-items-center">
      <p class="m-0 filename">
          <mat-icon *ngIf="svgIcon; else defaultIcon" [svgIcon]="svgIcon"></mat-icon>
          <ng-template #defaultIcon>
              <mat-icon>description</mat-icon>
          </ng-template>
          {{ text }}
          <!-- <ng-container *ngIf="comparing">
              <ng-container *ngIf="old; else newTextT">
                  <ng-container *ngIf="deleted"><mat-icon class="mr-0 ml-3 remove">remove</mat-icon></ng-container>
              </ng-container>
              <ng-template #newTextT><mat-icon class="mr-0 ml-3 add">add</mat-icon></ng-template>
          </ng-container> -->
      </p>
  </div>
  <div class="col-3 gap-1 d-flex justify-content-end actions">
      <button
          *ngIf="enableView"
          type="button"
          mat-icon-button
          class="document-button view"
          (click)="emitAction(onView)"
      >
          <mat-icon class="m-0">visibility</mat-icon>
      </button>
      <button
          *ngIf="enableDownload"
          type="button"
          mat-icon-button
          class="document-button download"
          (click)="emitAction(onDownload)"
      >
          <mat-icon class="m-0">get_app</mat-icon>
      </button>
      <button
          *ngIf="enableDelete"
          type="button"
          mat-icon-button
          class="document-button delete"
          (click)="emitAction(onDelete)"
      >
          <mat-icon class="m-0">close</mat-icon>
      </button>
  </div>
</div>
