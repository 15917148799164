// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_url: 'https://nssf-hos-api.myplgs.com/admin/api/v1',
  api_url: 'http://192.168.1.199:60001/admin/api/v1',
  // api_url: 'http://192.168.1.17:60001/admin/api/v1',
  LIMIT_PRINT_CARD: 20,
  DEFAULT_USER_IMAGE: "/assets/images/default_profile.png",
  VERSION: "0.0.1",

  NSSF_REDIRECT_URI: 'https://hos-admin.myplgs.com/auth/callback',
  NSSF_AUTH_URL: 'https://accountuat.nssf.gov.kh/connect',
  NSSF_ADMIN_AUTH_CLIENT_ID: '2ADA5F1E-357D-401B-92A3-F817C567FEDF',
  NSSF_ADMIN_AUTH_CLIENT_SECRET: 'CBEAF593-951D-424B-8098-A7C8C66BFDFD',
  NSSF_CLIENT_CODE_VERIFIER: 'od38lK15WykzQ19B-jbwtKFDa9HF8oiY4u1H87elLbU',

  /** OAUTH2 */
  NSSF_LOGOUT_REDIRECT_URI: 'https://ben-nssf.myplgs.com/login',
  IS_ENABLE_OAUTH: false,
  IS_SYSTEM_TESTING: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
