<div class="content" >
	<mat-dialog-content>
		<div class="photo-box-loading" *ngIf="!initIframe">
			<mat-spinner diameter="40" class="btn-spinner">
			</mat-spinner>
		</div>
		<iframe *ngIf="initIframe" id="viewer" class="main" width="100%" [src]="url | safe" frameborder="0" @popin></iframe>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button mat-dialog-close class="m-button-outlined">
			<mat-icon>logout</mat-icon> {{ 'button.close' | translate }}
		</button>
	</mat-dialog-actions>
</div>