export  enum STATUS {
            DRAFT = "DRAFT",
            SUBMITTED = "SUBMITTED",
            CONFIRMED = "CONFIRMED",
            PENDING = "PENDING",
            VERIFIED = "VERIFIED",
            APPROVED = "APPROVED",
            REVIEWING = "REVIEWING",
            REJECTED = "REJECTED",
            RETURNED = "RETURNED",
            ALLOCATE = "ALLOCATE",
            ASSIGN = "ASSIGN",
            SUSPENDED = "SUSPENDED",
            REOPENED = "REOPENED",
            CLOSED = "CLOSED",
            DENIED = "DENIED",
            PAID = "PAID",
            CREATED = "CREATED",
            DELETED = "DELETED",
            ACCEPTED = "ACCEPTED",
}