import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LANG } from 'src/app/models/enums/lang.enum';
import { UtilService } from 'src/app/services/util.service';

@Pipe({
  name: 'fromNow',
  pure: false
})
export class FromNowPipe implements PipeTransform {
  constructor(
    private util: UtilService,
  ) {
  }

  transform(value: Date | string, noSuffix: boolean): unknown {
    return moment(value).locale(this.util.currentLang).fromNow(noSuffix);
  }

}
