<div class="file">
        <label matRipple class="file-label">
            <input class="file-input" type="file" name="resume" [accept]="accept">
            <span *ngIf="!imgSrc" class="file-cta">
                <span class="file-icon">
                    <mat-icon>cloud_upload</mat-icon>
                </span>
                <span class="file-label">
                    Choose File
                </span>
            </span>
    
            <div class="img-container" [ngStyle]="{'max-height': allowFullHeight ? 'unset' : '325px'}">
                <img *ngIf="imgSrc" [src]="imgSrc" />
            </div>
            <span class="file-name">
                {{file?.name || 'No file selected'}}
                <span *ngIf="file" class="icon-container">
                    <span >{{file.type.split('/')[1].toUpperCase()}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 39">
                        <defs>
                            <style>
                            </style>
                        </defs>
                        <title>File</title>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path class="cls-one"
                                    d="M3.6,38A2.56,2.56,0,0,1,1,35.49v-32A2.56,2.56,0,0,1,3.6,1H17.76a2.06,2.06,0,0,1,1.43.58l.16.15,7,6.87A2,2,0,0,1,27,10.06V35.49A2.56,2.56,0,0,1,24.4,38Z">
                                </path>
                                <path class="cls-two"
                                    d="M17.76,2a1,1,0,0,1,.73.3l0,0,0,0,.12.11,6,5.81,1.08,1.06a1,1,0,0,1,.31.74V35.49A1.56,1.56,0,0,1,24.4,37H3.6A1.56,1.56,0,0,1,2,35.49v-32A1.56,1.56,0,0,1,3.6,2H17.76m0-2H3.6A3.55,3.55,0,0,0,0,3.51v32A3.55,3.55,0,0,0,3.6,39H24.4A3.55,3.55,0,0,0,28,35.49V10.06a3,3,0,0,0-.92-2.18L26,6.83,20,1,19.89.87A3,3,0,0,0,17.76,0Z">
                                </path>
                                <path class="cls-three"
                                    d="M26.38,9H23s-2,0-2.57-.08S20,8,20,8a10.07,10.07,0,0,1-1-3c0-1,0-3,1-4h0l6,5.83V7a1,1,0,0,0,1,1S26,9,26.38,9Z">
                                </path>
                                <path class="cls-four" d="M27,9H21a2,2,0,0,1-2-2V1"></path>
                            </g>
                        </g>
                    </svg>
                </span>
            </span>
            {{progress}}
        </label>
    </div>