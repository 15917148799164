import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullFeedbackAlertType } from 'src/app/models/enums/full-feedback-alert-type';

export interface FullFeedbackAlertData {
  type: FullFeedbackAlertType;
  title: string;
  messages: string[];
  error_msg?: string[];
  confirm?: boolean;
  positive?: string;
  positiveIcon?: string;
  negative?: string;
  negativeIcon?: string;
  requiredReason?: boolean;
}

@Component({
  selector: 'app-full-feedback-alert',
  templateUrl: './full-feedback-alert.component.html',
  styleUrls: ['./full-feedback-alert.component.scss']
})
export class FullFeedbackAlertComponent implements OnInit {

  descriprion: UntypedFormControl;

  readonly TYPE = FullFeedbackAlertType; // FOR NO MODIFY TO THE ENUM
	constructor(
		private dialogRef: MatDialogRef<FullFeedbackAlertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: FullFeedbackAlertData
	) {
    this.descriprion = new UntypedFormControl(null, Validators.required);
  }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize(): Promise<void> {
		this.dialogRef.addPanelClass(['animate__animated', 'animate__slideInUp', 'animate__faster']);
		await this.dialogRef.beforeClosed().toPromise();
    this.dialogRef.removePanelClass(['animate__animated', 'animate__slideInUp', 'animate__faster']);
    this.dialogRef.addPanelClass(['animate__animated', 'animate__fadeOutDown', 'animate__faster']);
  }

}
